import React from "react";
import Layout from '../components/layout';



export default function Home() {
  return (
    <Layout h2Data="This is my first attempt at this">      
        <div className="h-screen bg-rearview-mirror bg-cover bg-bottom">

          <div className="bg-black h-screen bg-opacity-50">
            <div className="relative px-4 xl:px-0 text-white container mx-auto md:flex items-center gap-8">
                    <div className="text-color w-full md:w-1/3 pt-8 lg:pt-36">
                        <h1 className="w-1/2 xl:w-full xl:text-7xl font-black f-f-l text-5xl">Connecticut Truck Accident Lawyers</h1>
                        <div className="text-xl lg:text-3xl pb-20 sm:pb-0 pt-10 xl:pt-20">
                            <h2>Bartlett Legal Group will help you win the compensation you deserve.</h2>
                        </div>
                        <button className="hover:opacity-90 text-xl w-full xl:text-3xl xl:w-11/12 mt-4 xl:mt-11 f-f-r py-6 bg-red-700 text-white font-bold">Free Consultation</button>
                    </div>
              </div>
            </div>
        </div>

        <div className="container mx-auto md:p-0 p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
              <div>
                  <h2 className="md:h-14">Were you injured in an accident with a truck?</h2>
                  <p className="text-xl">If you were involved in an accident with a large commercial vehicle, give yourself the peace of mind that your case is being handled by a law firm you can count on. Bartlett Legal Group will work tirelessly to ensure the other party is held accountable. Contact an experienced attorney today to find out how we can help you at <a href="tel:203-463-4939">(203) 463-4939</a>. This consultation is absolutely <em>FREE</em>.</p>
              </div>
              <div>
                  <h2 className="md:h-14">Contact us by email:</h2>
                  <div className="bg-gray-900 rounded text-white p-4 md:w-2/3 w-full">
                      <form id="email-contact" action="#" method="POST">
                          <div className="mt-1 flex rounded-md shadow-sm">
                              <input type="text" name="name" id="name" className="focus:ring-indigo-500 w-full mb-4" placeholder="Name" />
                          </div>
                          <div className="mt-1 flex rounded-md shadow-sm">
                              <input type="text" name="name" id="email" className="focus:ring-indigo-500 w-full mb-4" placeholder="Email Address" />
                          </div>
                          <div className="mt-1 flex rounded-md shadow-sm">
                              <input type="text" name="name" id="phone" className="focus:ring-indigo-500 w-full mb-4" placeholder="Phone" />
                          </div>
                          <div className="mt-1 flex rounded-md shadow-sm">
                              <textarea type="text" name="name" id="description" className="focus:ring-indigo-500 w-full mb-4" placeholder="Description"></textarea>
                          </div>
                          <button className="block bg-green-400 rounded py-1 pl-2 pr-3 font-semibold">
                              <svg className="w-6 inline" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                              Send
                          </button>
                      </form>
                  </div>
              </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 justify-center items-center text-center">
              <div>
                  <img src="../images/top-attorney-car-accident.png" alt="Top Car Accident Attorney"/> 
              </div>
              <div>
                  <img src="../images/blg-top-ten.jpg" alt="Top 10 Best Attorneys"/>
              </div>
              <div>
                  <img src="../images/top-ten-attorney.png" alt="Top Ten Attorney 2018"/>
              </div>
              <div>
                  <img src="../images/blg-avvo-top-attorney-wrongful-death.jpg" alt="Top Wrongful Death Attorney"/>
              </div>
          </div>
      </div>


      <div class="container mx-auto md:p-0 p-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
        <div>
            <h2 class="md:h-14 uppercase">About Bartlett Legal Group</h2>
            <p class="text-xl">Over the past decade, Bartlett Legal Group has strived tirelessly to <em><strong>"Give Victims A Voice"</strong></em> by providing the highest level of representation throughout their difficult personal injury cases. The attorneys at our firm are nationally recognized in the areas of personal injury and wrongful death. Our team of expert attorneys will ensure that every resource available will be used to win you the compensation that you deserve. Our firm has won millions of dollars in settlements across the country by aggressively litigating every claim.</p>
            <p class="text-xl mt-6">Contact an experienced attorney today to find out how we can help you at <a class="font-bold" href="tel:203-463-4939">(203) 463-4939</a>. This consultation is absolutely <em><strong>FREE</strong></em>.</p>
        </div>
        <div>
            <h2 class="md:h-14 uppercase">Contact us by email:</h2>
            <div class="bg-gray-900 rounded text-white p-4 md:w-2/3 w-full">
                <form id="email-contact" action="#" method="POST">
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="name" class="focus:ring-indigo-500 w-full mb-4" placeholder="Name" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="email" class="focus:ring-indigo-500 w-full mb-4" placeholder="Email Address" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="phone" class="focus:ring-indigo-500 w-full mb-4" placeholder="Phone" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <textarea type="text" name="name" id="description" class="focus:ring-indigo-500 w-full mb-4" placeholder="Description"></textarea>
                    </div>
                    <button class="block bg-green-400 rounded py-1 pl-2 pr-3 font-semibold">
                        <svg class="w-6 inline" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                        Send
                    </button>
                </form>
            </div>
        </div>
    </div>
    <div class="text-center border-t-4 border-gray-200 mb-16">
        <div class="my-8">
            <h2 class="uppercase">Our Expert Team</h2>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3">
            <div class="p-4">
                <img height="100" src="../images/attorney-frank-bartlett.jpg" alt="Attorney Frank Bartlett" />
                <div class="mt-2">
                    <h3 class="text-3xl">Frank C. Bartlett Jr.</h3>
                    <h4 class="text-2xl">Managing Partner</h4>
                </div>
                <div class="my-4">
                    <span class="text-xl">Attorney Bartlett is a top rated personal injury trial attorney. He strives to obtain justice and maximize results for his clients.</span>
                </div>
            </div>
            <div class="p-4">
                <img src="../images/bonnie-caines.jpg" alt="Legal Nurse Bonnie Caines" />
                <div class="mt-2">
                    <h3 class="text-3xl">Bonnie D. Caines</h3>
                    <h4 class="text-2xl">Legal Nurse</h4>
                </div>
                <div class="my-4">
                    <span class="text-xl">Ms. Caines’ vast medical experience and knowledge have proven to be invaluable to our firm and to our clients with medical issues relating to their legal matters.  She brings over 40 years of medical experience to our team.</span>
                </div>
            </div>
            <div class="p-4">
                <img src="../images/steven-allinson.jpg" alt="Attorney Steven Allinson" />
                <div class="mt-2">
                    <h3 class="text-3xl">Steven Allinson</h3>
                    <h4 class="text-2xl">Attorney</h4>
                </div>
                <div class="my-4">
                    <span class="text-xl">Attorney Allinson, Of Counsel to Bartlett Legal Group, LLC, handles Trusts, Conservatorships and other Probate matters associated with wrongful death and serious accident cases.</span>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="text-center border-t-4 border-gray-200 mb-16">
            <div class="my-8">
                <h2 class="uppercase">Accolades</h2>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-5 justify-center items-center text-center gap-y-8">
            <div>            
                <img src="../images/top-attorney-car-accident.png" alt="Top Car Accident Attorney" /> 
            </div>
            <div>
                <img src="../images/asla-4x-badge.png" alt="Top Wrongful Death Attorney" />
            </div>
            <div>
                <img src="../images/blg-top-ten.jpg" alt="Top 10 Best Attorneys" />
            </div>
            <div>
                <img src="../images/top-ten-attorney.png" alt="Top Ten Attorney 2018" />
            </div>
            <div>
                <img src="../images/blg-avvo-top-attorney-wrongful-death.jpg" alt="Top Wrongful Death Attorney" />
            </div>
        </div>
    </div>
</div>

<div class="container mx-auto md:p-0 p-4 bg-white my-4 md:my-24">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-16 md:gap-32 mb-10">
        <div>
            <h2 class="uppercase md:h-14">Were you injured in an accident with a truck?</h2>
            <p class="text-xl pt-4 ">If you were involved in an accident with a large commercial vehicle, give yourself the peace of mind that your case is being handled by a law firm you can count on. Bartlett Legal Group will work tirelessly to ensure the other party is held accountable.</p>
            <p class="text-xl pt-4 ">Contact an experienced attorney today to find out how we can help you at <a href="tel:203-463-4939">203-463-4939</a>. This consultation is absolutely <em><strong>FREE</strong></em>.</p>
        </div>
        <div>
            <h2 class="md:h-14">Contact us by email:</h2>
            <div class="bg-gray-900 rounded text-white p-4 md:w-2/3 w-full">
                <form id="email-contact" action="#" method="POST">
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="name" class="focus:ring-indigo-500 w-full mb-4" placeholder="Name" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="email" class="focus:ring-indigo-500 w-full mb-4" placeholder="Email Address" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="phone" class="focus:ring-indigo-500 w-full mb-4" placeholder="Phone" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <textarea type="text" name="name" id="description" class="focus:ring-indigo-500 w-full mb-4" placeholder="Description"></textarea>
                    </div>
                    <button class="block bg-green-400 rounded py-1 pl-2 pr-3 font-semibold">
                        <svg class="w-6 inline" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                        Send
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="bg-gray-900 py-8 md:py-24 md:my-16">
    <div class="text-large md:text-xl container mx-auto md:p-0 p-4 text-white text-left md:text-center">
        <div class="md:mb-16">
            <h2 class="uppercase">Trucking accident statistics</h2>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-y-8">
            <div class="text-green-400">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-16 md:h-24 w-16 md:w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" /></svg>
                538,000 truck involved accidents
            </div>
            <div class="text-green-400">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-16 md:h-24 w-16 md:w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" /></svg>
                159,000 injuries
            </div>
            <div class="text-green-400">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-16 md:h-24 w-16 md:w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>
                5,005 fatalities
            </div>
        </div>
    </div>

</div>


<div class="container mx-auto py-8 md:py-8 md:my-16">
    <div class="grid grid-cols-1 md:grid-cols-5 justify-center items-center text-center gap-y-8">
        <div>            
            <img src="../images/top-attorney-car-accident.png" alt="Top Car Accident Attorney" /> 
        </div>
        <div>
            <img src="../images/asla-4x-badge.png" alt="Top Wrongful Death Attorney" />
        </div>
        <div>
            <img src="../images/blg-top-ten.jpg" alt="Top 10 Best Attorneys" />
        </div>
        <div>
            <img src="../images/top-ten-attorney.png" alt="Top Ten Attorney 2018" />
        </div>
        <div>
            <img src="../images/blg-avvo-top-attorney-wrongful-death.jpg" alt="Top Wrongful Death Attorney" />
        </div>
    </div>
</div>



<div class="container mx-auto md:p-0 p-4 bg-white my-4 md:my-24">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-16 md:gap-32 mb-10">
        <div>
            <h2 class="uppercase md:h-14">Your case matters to us</h2>
            <p class="text-xl pt-4">Over the last decade, Bartlett Legal Group has maintained a commitment towards obtaining the best possible outcome for our clients. We invite you to reivew the results of several cases we have been involved in over this time. Each case is different and past results are never guaranteed, but our dedication to aggressively fighting on your behalf is.</p>
            <p class="text-xl pt-4 ">Contact an experienced attorney today to find out how we can help you at <a href="tel:203-463-4939">203-463-4939</a>. This consultation is absolutely <em><strong>FREE</strong></em>.</p>
        </div>
        <div>
            <h2 class="md:h-14 uppercase">Contact us by email:</h2>
            <div class="bg-gray-900 rounded text-white p-4 md:w-2/3 w-full">
                <form id="email-contact" action="#" method="POST">
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="name" class="focus:ring-indigo-500 w-full mb-4" placeholder="Name" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="email" class="focus:ring-indigo-500 w-full mb-4" placeholder="Email Address" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="phone" class="focus:ring-indigo-500 w-full mb-4" placeholder="Phone" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <textarea type="text" name="name" id="description" class="focus:ring-indigo-500 w-full mb-4" placeholder="Description"></textarea>
                    </div>
                    <button class="block bg-green-400 rounded py-1 pl-2 pr-3 font-semibold">
                        <svg class="w-6 inline" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                        Send
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="bg-gray-900 py-8 md:py-24 md:my-16">
    <div class="text-xl md:text-2xl container mx-auto md:p-0 p-4 text-white text-center">
        <div class="md:mb-16">
            <h2 class="uppercase">Our firm is proud to have secured over <em class="text-green-400">$29,000,000</em> for our clients since 2012</h2>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-y-16">
            <div class="text-yellow-300">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" /></svg>
                <p class="text-white">Dawn Andalora v. Joseph Falanga</p>
                <p class="text-green-400">$3,188,804</p>
            </div>
            <div class="text-yellow-300">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" /></svg>
                <p class="text-white">John Doe PPA v. Guay Roger</p>
                <p class="text-green-400">$1,500,000</p>
            </div>
            <div class="text-yellow-300">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" /></svg>
                <p class="text-white">Buchanon v. J&A Equities</p>
                <p class="text-green-400">$1,200,000</p>
            </div>
            <div class="text-yellow-300">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" /></svg>
                <p class="text-white">John Roe #1 v. Boy Scouts of America, et al.</p>
                <p class="text-green-400">$1,000,000</p>
            </div>
            <div class="text-yellow-300">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" /></svg>
                <p class="text-white">Colleen B. v. 1-3 Long Wharf Drive</p>
                <p class="text-green-400">$520,000</p>
            </div>
            <div class="text-yellow-300">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" /></svg>
                <p class="text-white">Donna M. v. Siemens</p>
                <p class="text-green-400">$200,000</p>
            </div>
        </div>
    </div>
</div>

<div class="container mx-auto py-8 md:py-8 md:my-16">
    <div class="grid grid-cols-1 md:grid-cols-5 justify-center items-center text-center gap-y-8">
        <div>            
            <img src="../images/top-attorney-car-accident.png" alt="Top Car Accident Attorney" /> 
        </div>
        <div>
            <img src="../images/asla-4x-badge.png" alt="Top Wrongful Death Attorney" />
        </div>
        <div>
            <img src="../images/blg-top-ten.jpg" alt="Top 10 Best Attorneys" />
        </div>
        <div>
            <img src="../images/top-ten-attorney.png" alt="Top Ten Attorney 2018" />
        </div>
        <div>
            <img src="../images/blg-avvo-top-attorney-wrongful-death.jpg" alt="Top Wrongful Death Attorney" />
        </div>
    </div>
</div>



---
title: "Connecticut Truck Accident Lawyers"
date: "2021-05-24"
tagline: "If you or someone you know has suffered an injury as a result of an accident with a commercial vehicle, Bartlett Legal Group will help you win the compensation you are entitled to."
---
<div class="container mx-auto 2xl:p-0 p-4 bg-white my-4 md:my-24">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 xl:gap-32 mb-10">
        <div>
            <h2 class="uppercase md h-24 lg:h-14">Were you injured in an accident with a truck?</h2>
            <p class="text-xl pt-4 ">If you were involved in an accident with a large commercial vehicle, give yourself the peace of mind that your case is being handled by a law firm you can count on. Bartlett Legal Group will work tirelessly to ensure the other party is held accountable.</p>
            <p class="text-xl pt-4 ">Contact an experienced attorney today to find out how we can help you at <a href="tel:203-463-4939">203-463-4939</a>. This consultation is absolutely <em><strong>FREE</strong></em>.</p>
        </div>
        <div>
            <h2 class="uppercase md h-24 lg:h-14">Contact us by email:</h2>
            <div class="bg-gray-900 rounded text-white p-4 2xl:w-2/3 w-full">
                <form id="email-contact" action="#" method="POST">
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="name" class="focus:ring-indigo-500 w-full mb-4" placeholder="Name" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="email" class="focus:ring-indigo-500 w-full mb-4" placeholder="Email Address" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" name="name" id="phone" class="focus:ring-indigo-500 w-full mb-4" placeholder="Phone" />
                    </div>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <textarea type="text" name="name" id="description" class="focus:ring-indigo-500 w-full mb-4" placeholder="Description"></textarea>
                    </div>
                    <button class="block bg-green-400 rounded py-1 pl-2 pr-3 font-semibold">
                        <svg class="w-6 inline" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                        Send
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="bg-gray-900 py-8 md:py-24 md:my-16">
    <div class="text-large md:text-xl container mx-auto md:p-0 p-4 text-white text-left md:text-center">
        <div class="md:mb-16">
            <h2 class="uppercase">Trucking accident statistics</h2>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-y-8">
            <div class="text-green-400 flex md:x-auto">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-16 md:h-24 w-16 md:w-24" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" /></svg>
                <div class="pt-5 md:pt-8">538,000 truck accidents</div>
            </div>
            <div class="text-green-400 flex md:mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-16 md:h-24 w-16 md:w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" /></svg>
                <div class="pt-5 md:pt-8">159,000 injuries</div>
            </div>
            <div class="text-green-400 flex md:mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-16 md:h-24 w-16 md:w-24 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="0.5" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>
                <div class="pt-5 md:pt-8">5,005 fatalities</div>
            </div>
        </div>
    </div>

</div>


<div class="container mx-auto py-8 md:py-8 md:my-16">
    <div class="grid grid-cols-1 md:grid-cols-5 justify-center items-center text-center gap-y-8">
        <div>            
            <img src="../images/top-attorney-car-accident.png" alt="Top Car Accident Attorney" /> 
        </div>
        <div>
            <img src="../images/asla-4x-badge.png" alt="Top Wrongful Death Attorney" />
        </div>
        <div>
            <img src="../images/blg-top-ten.jpg" alt="Top 10 Best Attorneys" />
        </div>
        <div>
            <img src="../images/top-ten-attorney.png" alt="Top Ten Attorney 2018" />
        </div>
        <div>
            <img src="../images/blg-avvo-top-attorney-wrongful-death.jpg" alt="Top Wrongful Death Attorney" />
        </div>
    </div>
</div>


      

    </Layout>)
}
